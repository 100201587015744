<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
    :transition="
      $vuetify.breakpoint.xs ? 'dialog-bottom-transition' : 'dialog-transition'
    "
    max-width="750"
    persistent
  >
    <v-card>
      <v-card-title class="primary" style="padding-top: 10px">
        <span class="white--text">ค้นหาขั้นสูง</span>
        <v-btn
          icon
          class="ml-auto white--text"
          style="margin-right: -8px"
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-6">
        <v-form
          v-model="isFormValid"
          ref="form"
          lazy-validation
          class="pt-0"
          enctype="multipart/form-data"
        >
          <v-row>
            <v-col
              v-for="(item, index) in filterContainer"
              :key="index"
              cols="12"
              sm="6"
            >
              <v-row align="center">
                <v-col cols="4">
                  <span>{{ item.name }}</span>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-if="item.model === 'road'"
                    v-model="filterForm[item.model]"
                    :items="itemLists[item.select.items]"
                    placeholder="เลือก"
                    outlined
                    dense
                    background-color="white"
                    :item-value="item.select['item-value']"
                    :item-text="item.select['item-text']"
                    :no-data-text="item.nodata"
                    clearable
                    hide-details
                  >
                    <template v-slot:item="{ item }">
                      {{ parseInt(item.code) }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ parseInt(item.code) }}
                    </template>
                  </v-select>
                  <v-select
                    v-else-if="item.type === 'select'"
                    v-model="filterForm[item.model]"
                    :items="itemLists[item.select.items]"
                    placeholder="เลือก"
                    outlined
                    dense
                    background-color="white"
                    :item-value="item.select['item-value']"
                    :item-text="item.select['item-text']"
                    clearable
                    :no-data-text="item.nodata"
                    hide-details
                  ></v-select>
                  <!-- km_start and km_end -->
                  <v-row v-else-if="item.model === 'km'">
                    <v-col class="pr-0">
                      <v-text-field
                        v-model="filterForm.km_start"
                        class="mr-2 right-input"
                        placeholder="0+000"
                        :rules="kmStartRules"
                        outlined
                        dense
                        background-color="white"
                        hide-details="auto"
                        @keypress="filterkm"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-center px-0">
                      <div class="mt-2">&#8211;</div>
                    </v-col>
                    <v-col class="pl-0">
                      <v-text-field
                        v-model="filterForm.km_end"
                        class="ml-2 right-input"
                        placeholder="1+000"
                        background-color="white"
                        :rules="kmEndRules"
                        outlined
                        dense
                        hide-details="auto"
                        @keypress="filterkm"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <div v-else-if="item.model === 'province'">
                    <v-autocomplete
                      v-model="filterForm[item.model]"
                      :items="itemLists['v-mbmms-province-exist-only']"
                      placeholder="เลือก"
                      outlined
                      dense
                      item-text="name_th"
                      item-value="province_code"
                      :no-data-text="item.nodata"
                      hide-details
                      clearable
                    ></v-autocomplete>
                  </div>
                  <div v-else class="d-flex align-center">
                    <v-text-field
                      v-model="filterForm[item.model]"
                      background-color="white"
                      placeholder="0"
                      outlined
                      dense
                      type="number"
                      class="right-input"
                      min="0"
                      hide-details
                    >
                      <template v-slot:prepend-inner>
                        <div
                          class="d-flex align-center justify-center"
                          style="
                            width: 40px;
                            height: 40px;
                            margin: -8px 4px 0 -12px;
                            background-color: #e1e1e1;
                            border-radius: 4px 0 0 4px;
                          "
                        >
                          &#8804;
                        </div>
                      </template>
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="0" @click="clearFilter">
          <v-icon left>mdi-close</v-icon>
          ล้างข้อมูล
        </v-btn>
        <v-btn
          class="white--text primary ml-2"
          style="width: 140px"
          :disabled="!isFormValid"
          @click="doSearch()"
        >
          ค้นหา
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal, oldVal) {
        this.dialog = newVal
      }
    },
    'filterForm.road': {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal) {
          delete this.filterForm.control
          this.getSectionPart(newVal)
        } else {
          delete this.filterForm.control
          this.itemLists.control = []
        }
      }
    },
    'filterForm.district': {
      immediate: true,
      handler (newVal, oldVal) {
        this.getRefRoad()
      }
    },
    'filterForm.division' (newVal, oldVal) {
      if (newVal) {
        this.itemLists.districtByDivision = this.itemLists[
          'v-mbmms-district-exist-only'
        ].filter((district) => district.division_code === newVal)
        const checkid = this.itemLists.districtByDivision.some(
          (id) => id.distrit_code === this.filterForm.district
        )
        if (!checkid) {
          delete this.filterForm.district
        }
      } else {
        delete this.filterForm.district
        delete this.filterForm.road
        this.itemLists.districtByDivision = []
        this.getRefRoad()
      }
    }
  },
  data: () => ({
    dialog: true,
    filterForm: {},
    province: [],
    filterContainer: [
      {
        model: 'division',
        name: 'สำนัก',
        type: 'select',
        nodata: 'ไม่มีสำนักให้เลือก',
        select: {
          items: 'v-mbmms-division-exist-only',
          'item-value': 'division_code',
          'item-text': 'name'
        }
      },
      {
        model: 'district',
        name: 'แขวงทางหลวง',
        type: 'select',
        nodata: 'โปรดเลือกสำนัก',
        select: {
          items: 'districtByDivision',
          'item-value': 'district_code',
          'item-text': 'name',
          nodata: 'โปรดเลือกสำนัก'
        }
      },
      {
        model: 'road',
        name: 'หมายเลขทางหลวง',
        type: 'select',
        nodata: 'ไม่มีหมายเลขทางหลวงให้เลือก',
        select: {
          items: 'road',
          'item-value': 'road_id',
          'item-text': 'label'
        }
      },
      {
        model: 'control',
        name: 'ตอนควบคุม',
        type: 'select',
        nodata: 'โปรดเลือกหมายเลขทางหลวง',
        select: {
          items: 'control',
          'item-value': 'code',
          'item-text': 'label',
          nodata: 'โปรดเลือกหมายเลขทางหลวง'
        }
      },
      {
        model: 'km',
        name: 'ช่วง กม. ที่'
      },
      {
        model: 'type',
        name: 'ชนิดโครงสร้างหลัก',
        type: 'select',
        nodata: 'ไม่มีชนิดโครงสร้างหลักให้เลือก',
        select: {
          items: 'bridge_structure',
          'item-value': 'id',
          'item-text': 'name'
        }
      },
      {
        model: 'widthPavement',
        name: 'ความกว้างผิวทาง (ม.)'
      },
      {
        model: 'width',
        name: 'ความยาวสะพาน (ม.)'
      },
      {
        model: 'province',
        name: 'จังหวัด',
        nodata: 'ไม่มีจังหวัดให้เลือก'
      },
      {
        model: 'bridge_category',
        name: 'ประเภทสะพาน',
        type: 'select',
        nodata: 'ไม่มีประเภทสะพานให้เลือก',
        select: {
          items: 'bridge_category',
          'item-value': 'id',
          'item-text': 'name'
        }
      },
      {
        model: 'configRangeSRID',
        name: 'S.R.',
        type: 'select',
        nodata: 'ไม่มี S.R. ให้เลือก',
        select: {
          items: 'sr',
          'item-value': 'id',
          'item-text': 'name'
        }
      }
    ],
    itemLists: {
      road: [],
      control: [],
      bridgeType: [],
      province: []
    },
    name: null,
    isFormValid: false
  }),
  mounted () {
    // this.getRef('province')
    this.getRef('bridge_structure')
    this.getRef('bridge_category')
    this.getRef('v-mbmms-district-exist-only')
    this.getRef('v-mbmms-division-exist-only')
    this.getRef('v-mbmms-province-exist-only')
    this.getRefRoad()
    this.getRefFilterSr()
  },
  computed: {
    kmStartRules () {
      const rules = []
      if (this.filterForm.km_start) {
        const rule = (v) =>
          (v && /^[0-9]+[+][0-9][0-9][0-9]$/.test(v)) ||
          /^[0-9]+[+][0-9][0-9][0-9]\.[0-9]+$/.test(v) ||
          'ผิดรูปแบบ'
        rules.push(rule)
      }
      return rules
    },
    kmEndRules () {
      const rules = []
      if (this.filterForm.km_end) {
        const rule = (v) =>
          (v && /^[0-9]+[+][0-9][0-9][0-9]$/.test(v)) ||
          /^[0-9]+[+][0-9][0-9][0-9]\.[0-9]+$/.test(v) ||
          'ผิดรูปแบบ'
        rules.push(rule)
      }
      return rules
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    clearFilter () {
      console.log(this.filterForm)
      this.filterForm = {}
    },
    doSearch () {
      if (this.$refs.form.validate()) {
        this.$emit('close', this.filterForm)
      }
      // this.filter = {}
    },
    async getRefRoad () {
      const filter = {
        depot_code: this.filterForm.depot_code,
        district_code: this.filterForm.district
      }
      const roads = await this.$store.dispatch('ref/getRef', {
        method: 'v-mbmms-road-exist-only',
        filter
      })
      for (const road of roads) {
        road.label = `${parseInt(road.code)}`
      }
      const checkid = roads.some((id) => id.road_id === this.filterForm.road)
      if (!checkid) {
        delete this.filterForm.road
        delete this.filterForm.control
        this.itemLists.control = []
      }
      this.itemLists.road = roads
    },
    async getSectionPart (roadid) {
      const controls = await this.$store.dispatch('ref/getRef', {
        method: `v-mbmms-section-part-exist-only?filters=road_id:${roadid}`
      })
      for (const control of controls) {
        control.label = `${control.code} - ${control.name}`
      }
      this.itemLists.control = controls
    },
    async getRef (method) {
      this.itemLists[method] = await this.$store.dispatch('ref/getRef', {
        method
      })
    },
    async getRefFilterSr () {
      this.itemLists.sr = await this.$store.dispatch('getRefFilterSr')
    },
    filterkm (event) {
      event = event || window.event
      const expect = event.target.value.toString() + event.key.toString()
      if (
        !/^[0-9]*$/.test(expect) &&
        !/^[0-9]+[+]$/.test(expect) &&
        !/^[0-9]+[+][0-9]*$/.test(expect)
      ) {
        event.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
